import { reactive, defineComponent, onMounted, } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import ForgotPasswordPopup from "@/presentation/components/modals/organization-sendmail-reset-password/OrganizationSendMailForgotPassword.vue";
import SendmMailForgotPasswordPopup from "@/presentation/components/modals/organization-forgot-password/OrganizationForgotPassword.vue";
import LanguageUtil from "@/commons/LanguageUtil";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import AuthInteractor from "@/domain/usecases/AuthInteractor";
import RoutePath from "@/commons/RoutePath";
import DIContainer from "@/core/DIContainer";
import { useStore } from "vuex";
import Constant from "@/commons/Constant";
import * as agh from "agh.sprintf";
export default defineComponent({
    name: "CompanyResetPassword",
    components: {
        Field: Field,
        Form: Form,
        ErrorMessage: ErrorMessage,
        ForgotPasswordPopup: ForgotPasswordPopup,
        SendmMailForgotPasswordPopup: SendmMailForgotPasswordPopup,
        Breadcrumb: Breadcrumb
    },
    setup: function () {
        var router = useRouter();
        var route = useRoute();
        var store = useStore();
        var authInteractor = DIContainer.instance.get(AuthInteractor);
        /* eslint-disable @typescript-eslint/camelcase */
        var PASSWORD_PATTERN = Constant.PASSWORD_PATTERN;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: '/', name: "TOP"
                },
                {
                    route: '', name: "パスワード変更"
                }
            ],
            errorLoginValidation: null,
            showSendMailForgotPassword: false,
            showForgotPassword: false,
            token: "",
            errorMessage: null
        });
        var schema = yup.object().shape({
            password: yup
                .string()
                .matches(Constant.PASSWORD_PATTERN, state.aLang.validation_format_password)
                .max(20, agh.sprintf(state.aLang.validation_max_length, 20))
                .min(8, agh.sprintf(state.aLang.validation_min_length, 8))
                .required(state.aLang.validation_required),
            password_confirmation: yup
                .string()
                .matches(Constant.PASSWORD_PATTERN, state.aLang.validation_format_password)
                .max(20, agh.sprintf(state.aLang.validation_max_length, 20))
                .min(8, agh.sprintf(state.aLang.validation_min_length, 8))
                .required(state.aLang.validation_required)
                .oneOf([yup.ref("password"), ""], state.aLang.validation_confirm_password)
        });
        function openSendMailPopup() {
            state.showForgotPassword = false;
            state.showSendMailForgotPassword = true;
        }
        function onSubmit(values) {
            state.errorMessage = null;
            if (!state.token) {
                return;
            }
            return authInteractor
                .organizationResetPassword(state.token, values.password, values.password_confirmation)
                .then(function (result) {
                if (window.history.state.back == RoutePath.LOGIN ||
                    window.history.state.back == null) {
                    router.push(RoutePath.ORGANIZATION_RESET_PASSWORD_SUCCESS);
                }
                else {
                    router.push(window.history.state.back);
                }
            })
                .catch(function (error) {
                router.push(RoutePath.ORGANIZATION_RESET_PASSWORD_FAIL);
            });
        }
        onMounted(function () {
            state.token = route.query.forgot_token;
            return authInteractor
                .organizationValidateResetToken(state.token)
                .then(function (result) { }, function (error) {
                router.push(RoutePath.ORGANIZATION_RESET_PASSWORD_FAIL);
            });
        });
        return {
            state: state,
            schema: schema,
            openSendMailPopup: openSendMailPopup,
            onSubmit: onSubmit
        };
    }
});
