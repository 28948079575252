<template>
    <div>
        <div class="comLogin short-container">
            <Breadcrumb :breadcrumb="state.listBreadcrumb" />
            <div class="comLogin__container">
                <div class="comLogin__container-title">
                    新しいパスワードを入力してください
                </div>

                <Form
                    class="horizontal"
                    @submit="onSubmit"
                    v-slot="{ errors, isSubmitting }"
                    :validation-schema="schema"
                >
                    <div class="form-ctrl">
                        <label for="password" class="text"
                            >新しいパスワード</label
                        >
                        <div class="form-group">
                            <Field
                                maxlength="20"
                                id="password"
                                type="password"
                                name="password"
                                as="input"
                                placeholder="英数字8〜20文字で入力してください"
                            />
                            <ErrorMessage
                                v-if="errors.password"
                                :message="errors.password"
                            />
                        </div>
                    </div>

                    <div class="form-ctrl">
                        <label for="re-password" class="text"
                            >新しいパスワード（再入力）</label
                        >
                        <div class="form-group">
                            <Field
                                maxlength="20"
                                id="re-password"
                                type="password"
                                name="password_confirmation"
                                as="input"
                                placeholder="確認のため再度ご入力ください"
                            />

                            <ErrorMessage
                                v-if="errors.password_confirmation"
                                :message="errors.password_confirmation"
                            />
                        </div>
                    </div>

                    <div class="comLogin__container-button">
                        <button
                            :disabled="isSubmitting"
                            :class="{ submitting: isSubmitting }"
                            type="submit"
                            class="btn-lg sweep-to-top"
                        >
                            <i
                                v-if="isSubmitting"
                                class="fas fa-circle-notch fa-spin"
                            ></i>
                            パスワードを再設定する
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/reset-password/OrganizationResetPassword.ts"></script>

<style lang="scss" scoped>
@import "@/presentation/views/organization/reset-password/OrganizationResetPassword.scss";
</style>
